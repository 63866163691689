import {gql, useMutation, useQuery} from '@apollo/client'
import {Link, navigate} from 'gatsby'
import React, {useState} from 'react'
import {PlusCircleIconSmall, Skeleton} from '../components'
import Page from '../components/page'
import {
  Avatar,
  Banner,
  Button,
  ConfirmDialog,
  FormInput,
  Modal,
  ModalProps,
  PageHeading,
  SectionHeading,
  Table,
} from '../components/tailwind'
import {TeamAddUserMutation} from '../graphql/TeamAddUserMutation'
import {TeamGetUsersQuery} from '../graphql/TeamGetUsersQuery'
import {Role, User, ROLE_NAMES} from '../models'

export const GET_USERS = gql`
  query TeamGetUsersQuery {
    users {
      id
      name
      email
      picture
      roles
    }
  }
`

export const DELETE_USER = gql`
  mutation TeamDeleteUserMutation($id: ID!) {
    deleteUser(id: $id)
  }
`

export const ADD_USER = gql`
  mutation TeamAddUserMutation($name: String!, $email: String!) {
    addUser(name: $name, email: $email) {
      id
      name
      email
      picture
      roles
    }
  }
`

const AddUserDialog: React.FC<ModalProps> = ({onDismiss}) => {
  const [name, setName] = useState<string>('')
  const [nameError, setNameError] = useState<string>()
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<string>()
  const [addUser, {loading: adding, error}] = useMutation<TeamAddUserMutation>(ADD_USER)

  const handleSave = async (e: React.FormEvent | React.MouseEvent) => {
    e.preventDefault()

    setNameError(undefined)
    setEmailError(undefined)

    if (!name) {
      setNameError('Name is required')
      return
    }

    if (!email) {
      setEmailError('Email is required')
      return
    }

    if (!/^[\w!#$%&'*+./=?^`{|}~-]+@[\dA-Za-z-]+(?:\.[\dA-Za-z-]+)*$/.test(email)) {
      setEmailError('Not a valid email address')
      return true
    }

    const {
      data: {
        addUser: {id},
      },
    } = await addUser({variables: {name, email}})

    onDismiss()
    navigate(`/team/${id}`)
  }

  const handleNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const handleEmailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  return (
    <Modal onDismiss={onDismiss}>
      <SectionHeading title="Add User" />
      <form
        autoComplete="off"
        className="mt-3 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
        onSubmit={handleSave}
      >
        <FormInput required autoFocus label="Name" value={name} error={nameError} onChange={handleNameChanged} />
        <FormInput
          required
          className="mt-3 sm:mt-0"
          label="Email"
          value={email}
          error={emailError}
          onChange={handleEmailChanged}
        />

        <Button fullWidth className="mt-5 sm:mt-8 sm:col-start-2" busy={adding} type="submit" onClick={handleSave}>
          Save
        </Button>
        <Button fullWidth secondary className="mt-3 sm:mt-8 sm:col-start-1" onClick={onDismiss}>
          Cancel
        </Button>

        {error && <Banner type="error" content={error.message} className="mt-3 sm:col-span-2" />}
      </form>
    </Modal>
  )
}

const TeamPage: React.FunctionComponent = () => {
  const {data, loading, refetch} = useQuery<TeamGetUsersQuery>(GET_USERS)
  const users = data?.users
    ?.filter((user) => !user.roles.includes(Role.NON_USER))
    .sort((a, b) => a.name.localeCompare(b.name))
  const [deleteUserMutation] = useMutation(DELETE_USER)
  const [showAddUser, setShowAddUser] = useState(false)
  const [deleteUser, setDeleteUser] = useState<User>()

  const handleDeleteUser = (user: User) => {
    setDeleteUser(user)
  }

  const handleDeleteConfirmation = async (result: boolean) => {
    setDeleteUser(undefined)

    if (result) {
      await deleteUserMutation({variables: {id: deleteUser.id}})
      refetch()
    }
  }

  const handleAddUser = () => {
    setShowAddUser(true)
  }

  const handleAddUserDismissed = () => {
    setShowAddUser(false)
    refetch()
  }

  if (loading)
    return (
      <Page title="Team">
        <Skeleton />
      </Page>
    )

  return (
    <Page title="Team">
      <PageHeading title="Team">
        <Button size="small" icon={PlusCircleIconSmall} className="mt-3 md:mt-0" onClick={handleAddUser}>
          Add User
        </Button>
      </PageHeading>

      {users && (
        <Table
          items={users}
          columns={[
            {
              name: 'Name',
              value: (u) => (
                <div className="flex items-center space-x-2">
                  <Avatar user={u} size="tiny" />
                  <span>{u.name}</span>
                </div>
              ),
            },
            {name: 'Email', value: (u) => u.email},
            {name: 'Roles', value: (u) => u.roles.map((role) => String(ROLE_NAMES[role])).join(', ')},
            {
              value: (u) => (
                <div className="flex justify-end space-x-2 whitespace-nowrap text-right text-sm leading-5">
                  <Link to={u.id} className="font-medium text-indigo-600 hover:text-indigo-900">
                    Edit
                  </Link>
                  <a
                    className="font-medium text-indigo-600 hover:text-indigo-900 cursor-pointer"
                    onClick={() => handleDeleteUser(u)}
                  >
                    Delete
                  </a>
                </div>
              ),
            },
          ]}
        />
      )}

      {deleteUser && (
        <ConfirmDialog title={`Confirm Delete: ${deleteUser.name}`} onDismiss={handleDeleteConfirmation}>
          <h1 className="text-gray-700">Are you sure you want to delete this user?</h1>
          <Banner type="error" content="This action cannot be undone" />
        </ConfirmDialog>
      )}

      {showAddUser && <AddUserDialog onDismiss={handleAddUserDismissed} />}
    </Page>
  )
}

export default TeamPage
